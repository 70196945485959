export default function (pairSeparator, fieldSeparator, source) {
  if(source){
    return source.split(pairSeparator).reduce((dictionary, stringChunk) => {
      const [key, value] = stringChunk.split(fieldSeparator);
      dictionary[key] = value;
      return dictionary;
    }, {});
  } else{
    return {};
  }
}
